import React from "react"
import styled from "styled-components"
import useAuthenticationCheck from "../hooks/useAuthenticationCheck"
import { useUser } from "../context/UserContext" // adjust the import path as needed
import { useToken } from "../context/UseToken"
import Premium from "../components/header/premium"

const AccountPage: React.FC = () => {
  useAuthenticationCheck()
  const { user } = useUser()
  const { getToken } = useToken()

  const safeKeys = [
    "id",
    "email",
    "name",
    "isPremium",
    "isVerified",
    "createdAt",
    "role",
  ]

  const deleteAccount = async () => {
    const confirmation = window.confirm(
      "Are you sure you want to delete your account? This action is irreversible."
    )
    if (!confirmation) return

    try {
      const token = await getToken()
      const response = await fetch(
        `${process.env.GATSBY_API_URL}/v1/user?id=`,
        {
          method: "DELETE", // Or any HTTP method that is implemented in your backend for account deletion.
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ userId: user?.id }),
        }
      )

      if (!response.ok) throw new Error("Failed to delete account")
      else {
        alert("Account deleted successfully!")
        window.location.href = "/"
      }
    } catch (error) {
      console.error("Error deleting account:", error)
      alert("Failed to delete account. Please try again later.")
    }
  }

  return (
    <>
      <Wrapper>
        <Card>
          <SubTitle>Your user information</SubTitle>
          {user && (
            <UserDataWrapper>
              {safeKeys.map(key => (
                <StyledContainer key={key}>
                  <StyledInnerLabel>{key}</StyledInnerLabel>
                  <StyledInput
                    type="text"
                    value={(user as any)[key] + "" || ""}
                    disabled
                    placeholder={`Enter your ${key}`}
                  />
                </StyledContainer>
              ))}
              <Premium />
              <DeleteAccountButton onClick={deleteAccount}>
                Delete account
              </DeleteAccountButton>
            </UserDataWrapper>
          )}
        </Card>
      </Wrapper>
    </>
  )
}
const Wrapper = styled.div`
  width: 100%;
  height: 80vh;
  display: flex;
  justify-content: center;
  place-items: center;
`

const Card = styled.div`
  background-color: white;
  border-radius: 5px;
  padding: 50px 20px 50px 20px;
  margin: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  place-items: center;
  flex-direction: column;
  width: 500px;
`

const SubTitle = styled.div`
  font-size: 20px;
  margin-bottom: 20px;
`

const UserDataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
`

const StyledContainer = styled.div`
  position: relative;
  align-items: center;
  width: 100%;
  border-radius: 8 8 0 0;
  background-color: white;
`

const StyledInnerLabel = styled.p`
  position: absolute;
  top: 3px;
  color: #b6bbc6;
  &.prefix {
    max-width: 100px;
    padding-left: 16px;
  }
  &.suffix {
    max-width: fit-content;
    padding-right: 16px;
    right: 0;
  }
`

const StyledInput = styled.input`
  width: 100%;
  background-color: transparent;
  outline: none;
  border: none;
  line-height: 2;
  padding-right: 100px;
  padding-left: 100px;
`

const DeleteAccountButton = styled.button`
  background-color: white;
  border: none;
  text-decoration: underline;
  font-size: 14px;
  margin-top: 40px;
`

export default AccountPage
