import * as React from "react"
import styled from "styled-components"
import { useAuth0 } from "@auth0/auth0-react"
import colors from "../../styles/colors"
import { loadStripe } from "@stripe/stripe-js"
import { useUser } from "../../context/UserContext" // adjust the import path as needed
import { useToken } from "../../context/UseToken"
import { useEffect } from "react"

const Premium: React.FC = () => {
  const { user } = useUser()
  const { isAuthenticated } = useAuth0()
  const { getToken } = useToken()

  useEffect(() => {
    if (user) {
      console.log("PremiumButton - user is:", user)
    }
  }, [user])

  const handleBuyPremium = async () => {
    // Fetch the /create-checkout-session endpoint from our backend
    fetch(`${process.env.GATSBY_API_URL}/stripe/create-checkout-session`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${await getToken()}`,
      },
      body: JSON.stringify({
        subscriptionId: process.env.GATSBY_STRIPE_SUBSCRIPTION_ID,
      }),
    })
      .then(function (result) {
        return result.json()
      })
      .then(async function (data) {
        console.log("Return data: ", data)
        if (data.error) {
          alert(data.error)
          return
        }
        const stripe = await loadStripe(
          process.env.GATSBY_STRIPE_PUBLICKEY || ""
        ) // Load Stripe with your public key

        stripe
          ?.redirectToCheckout({
            sessionId: data.sessionId,
          })
          .then(function (result) {
            // If `redirectToCheckout` fails due to a browser or network
            // error, display the localized error message to your customer.
            if (result.error) {
              alert(result.error.message)
            }
          })
      })
  }

  const handleCancelSubscription = async () => {
    const confirmation = window.confirm(
      "Are you sure you want to cancel? Your subscription will immediately be cancelled and you will no longer have access to the premium features."
    )
    if (confirmation) {
      if (!user) {
        console.warn("No user info found")
        return
      }

      fetch(`${process.env.GATSBY_API_URL}/stripe/cancel-subscription`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${await getToken()}`,
        },
      })
        .then(response => response.json())
        .then(data => {
          console.log(data)
          window.location.reload()
        })
        .catch(error => {
          console.error("Error:", error)
          window.alert(
            "Something went wrong. Please try again or contact customer support."
          )
        })
    } else {
      // The user clicked Cancel, so don't proceed.
      console.log("User cancelled.")
    }
  }

  return (
    <HeaderStyling>
      {isAuthenticated && user && !user?.isPremium && (
        <PremiumButton onClick={handleBuyPremium}>Buy premium</PremiumButton>
      )}
      {isAuthenticated && user && user?.isPremium && (
        <CancelSubscriptionButton onClick={handleCancelSubscription}>
          Cancel subscription
        </CancelSubscriptionButton>
      )}
    </HeaderStyling>
  )
}

const HeaderStyling = styled.header`
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px;
  max-height: 50px;
  border-bottom: 0.5px solid ${colors.grey1};
`

const PremiumButton = styled.button`
  margin-right: 20px;
  border: 2px solid ${colors.green2};
  font-weight: 600;
  color: ${colors.green2};
  background-color: white;
  &:hover {
    background-color: ${colors.green2};
    color: white;
  }
`

const CancelSubscriptionButton = styled.button`
  margin-right: 20px;
  border: 1px solid ${colors.grey6};
  color: ${colors.grey6};
  background-color: white;
  &:hover {
    background-color: ${colors.grey6};
    color: white;
  }
`

export default Premium
